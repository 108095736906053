.footer{
	position: relative;
	color: #a08b8c;
	font-family: sans-serif;
	// min-height: 470px;
	padding: 70px 0;
	a{
		transition: .3s ease;
		&:hover{
			color: lighten(#a08b8c, 20%);
		}
	}
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top:0;
		left:0;
		background-color: rgba(130,33,38,.8);
	}
	.container1220{
		position: relative;
		.row{
			margin: 0 -10px;
			.col{
				padding: 0 10px;
			}
		}
	}

	.footer-copy{
		.img{
			margin-right: 15px;
		}
		.text-copy{
			letter-spacing: 2px;
			font-size: 14px;
		}
	}

	ul.footer-navbar{
		display: flex;
		align-items: center;
		margin: 50px 0 25px -30px;
		li{
			padding: 3px 20px;
			margin: 0 10px;
		}
	}

	.footer-subscribe{
		letter-spacing: 1px;
		.text{
			line-height: 20px;
			span{
				display: block;
			}	
		}
		.form{
			margin-top: 20px;
			input[type="text"]{
				width: 290px;
			}
			.btn--primary{
				margin-left: 15px;
				color: #a08b8c;
			}
			.field{
				width: inherit;
			}
			.text-error{
				line-height: 28px;
				color: #a08b8c;
			}
		}
	}
}
.m-footer{
	display: none;
}

@media only screen and (max-width: 768px){
	.footer{display: none;}
	.m-footer{
		display: block;
		background-color: #56292a;
		color: #fff;
		font-family: sans-serif;
		padding: 30px 20px;
		.text-copy{
			color: #a08b8c;
			letter-spacing: 1.5px;
		}
		.footer-subscribe{
			letter-spacing: 1px;
			.text{
				line-height: 20px;
				span{
					display: block;
				}	
			}
			.form{
				text-align: left;
				margin-top: 20px;
				input[type="text"]{
					width: 290px;
				}
				.btn--primary{
					margin-left: 15px;
					color: #FFF;
				}
				.field{
					width: inherit;
				}
				.text-error{
					line-height: 28px;
					color: #fff;
					font-size: 12px;
				}
			}
		}
		.img{
			img{
				margin: auto;
			}
		}
		ul.m-menu{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin: 20px 0 35px;
			li{
				width: 50%;
				padding: 10px;
			}
		}
	}
}