.slick-dotted{
	text-align: center;
	.slick-dots{
		position: absolute;
		margin: 0 auto;
		left: 0;
		right: 0;
		bottom: 20px;
		li{
			display: inline-block;
			font-size: 0;
			margin: 4px;
			opacity: .8;
			&.slick-active,
			&:hover{
				opacity: 1;
			}
			button{
				width: 8px;
				height: 8px;
				border-radius: 100%;
				padding: 0;
			}
		}
	}
	&.slick-slider{
		margin: 0;
	}
}