.page--download{
	background: #F2F2F2;
	height: 100vh;
	.download-landing{
		width: 322px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 40vh;
		.logo-landing{
				display: block;
				margin-left: auto;
			  	margin-right: auto;
		}
		.btn-download{
			width: 100%;
			height: 46px;
			background: #B50606;
			border-radius: 8px;
			margin-top: 30px;
			color: #FFFFFF;
			font-size: 16px;
			line-height: 19px;
			letter-spacing: -0.32px;
		}
	}
	
	.download-app{
		.download-banner{
			width: 100%;
  			height: 232px;
  			margin-bottom: 39px;
  			.img-banner{
  				position: absolute;
  				width: 100%;
				height: 232px;
				object-fit: cover;
				object-position: top;
			}
			@media screen and (max-width: 768px){
				height: 240px;
				.img-banner{
					height: 240px;
				}
			}
		}
		.download-content{
			text-align: center;
			width: 345px;
			margin-left: auto;
			margin-right: auto;
			.logo-landing{
				display: block;
			  	margin: 0px auto 16px auto;
			}
			.title{
				font-size: 24px;
				line-height: 28px;
				letter-spacing: 0.36px;
				color: #000000;
				margin-bottom: 12px;
			}
			.detail{
				font-size: 16px;
				line-height: 25px;
				letter-spacing: -0.32px;
				color: #5A5F66;
				margin-bottom: 24px;
			}
			.store-row{
				display: flex;
				width: 345px;
			    padding-left: 22px;
			    padding-right: 22px;
				.store-column{
					flex: 50%;
  					padding: 0px 7px 0px 7px;
				}
			}
		}
	}
}
