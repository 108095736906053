.page--contact{
	.box-section{
		margin: 15px 0;
		.title{
			@extend .dtlnobel.bold;
			font-size: 41px;
			letter-spacing: 1px;
			color: #c05e62;
		}
		.subtitle{
			@extend .dtlnobel.bold;
			font-size: 27px;
			letter-spacing: 0.87px;
			color: #c05e62;
		}
		p{
			font-family: sans-serif;
		}
	}
	.restaurant-location{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		margin-top: 15px;
		.restaurant-location-item{
			width: susy-span(12 of 12);
			padding: 15px 10px;
		}
		h6{
			@extend .dtlnobel.bold;
			font-size: 15px;
			margin-bottom: 15px;
		}
		p{
			font-size: 15px;
			font-family: sans-serif;
		}
	}
	.location-contact{
		font-size: 15px;
		margin-top: 15px;
		label{
			display: block;
			font-family: sans-serif;
			line-height: 21px;
			font-size: inherit;
			span{margin-right: 10px;}
		}
	}
	.location-socmed{
		font-size: 15px;
		margin-top: 15px;
		font-family: sans-serif;
		font-weight: 700;
		.socmed-list{
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			i{
				flex-shrink: 0;
				margin-right: 10px;
			}
		}
	}
	.contact-detail{
		padding: 50px 0;
		.row{
			margin: 0 -10px;
			.col{
				padding: 0 10px;
			}
		}
		.restaurant-location{
			.restaurant-location-item{
				width: susy-span(6 of 12);
				p{
					min-height: 130px;
				}
			}
		}
	}
	.contact-detail-mobile{
		display: none;
	}
	.contact-form{
		padding: 40px 20px;
		font-family: sans-serif;
		background-image: linear-gradient(-180deg, #4A2325 0%, #715355 100%);
		color: #fff;
		margin-top: 30px;
		.field{
			margin: 0 0 15px 0;
			label,
			input,
			select{
				flex-shrink: 0;
			}
			.label{
				padding-right: 10px;
				font-size: 14px;
				letter-spacing: 1.2px;
				padding-top: 9px;
			}
		}
		.checkbox-group{
			margin-bottom: 15px;
		}
		.checkbox-wrapper{
			padding: 0 10px;
		}
		.btn-submit{
			border: 1px solid #fff;
			background-color: transparent;
			padding: 5px 15px;
			color: #fff;
			letter-spacing: 2px;
			border-radius: 2px;
		}
	}
}


@media only screen and (max-width: 768px){
	.page--contact{
		.contact-detail{display: none;}
		.section.banner{display: none;}
		.box-section{
			margin: 15px 0;
			.title{
				font-size: 22px;
			}
			p{
				font-family: inherit;
				line-height: 21px;
			}
			.checkbox-group{
				.checkbox-wrapper{
					padding: 0;
					.check{
						&+ label{
							padding: 6px 7px 5px 27px;    
							font-size: 12px;
						}
					}
					.check--2{
					    &+ label{
					        padding-left: 25px;
					        &:before{
					            border:1px solid transparent;
					            background-color: #000;
					        }
					        &:after{
					            background-image: url(/images/ico/icon-check-white.svg);
					        }
					    }
					    &:checked,
					    &.checked{
					        &+ label{
					            &:before{
					                border:1px solid #CDCDCD;
					            }
					            &:after{
					                transform: scale(1);
					                opacity: 1;
					            }
					        }
					    }        
					}
				}
			}
		}
		.contact-form{
			padding: 0;
			font-family: sans-serif;
			background-image: none;
			margin-top: 20px;
			color: #000;
			.field{
				margin: 0 0 15px 0;
				label,
				input,
				select{
					flex-shrink: 0;
				}
				.label{
					padding-right: 10px;
					font-size: 12px;
					letter-spacing: 0.5px;
					padding-top: 4px;
				}
			}
			.checkbox-group{
				margin-bottom: 10px;
			}
			.checkbox-wrapper{
				padding: 0 10px;
			}
			.btn-submit{
				border: 1px solid #010101;
				padding: 5px 15px;
				color: #010101;
				font-family: inherit;
				font-weight: 700;
			}
		}
		.contact-detail-mobile{
			display: block;
			padding: 20px 15px 20px 60px;
			position: relative;
			min-height: 150px;
			font-family: sans-serif;
			.section-name{
				position: absolute;
				transform: rotate(270deg);
				left: -20px;
				top: 65px;
			}
			.box-section{
				font-family: 'PT Sans', sans-serif;
			}
		}
		form{
			.field{
				input,
				select,
				textarea{
					height: 20px;
					background-color: #000;
					color: #fff;
					border: 0;
					font-size: 12px;
				}
				select{
					background-image: url(/images/ico/ico-chevrondown-white.png);
					background-size: 19px 20px;
					background-color: #000 !important;
					color: #fff !important;
				}
			}
		}
	}
}


@media only screen and (max-width: 520px){
	.page--contact{
		.accordion{
			.accordion-list{
				.accordion-body{
					.restaurant-location{
						margin-top: 0;
						.restaurant-location-item{
							padding: 10px;
						}
						h6{
							font-size: 12px;
							margin-bottom: 10px;
						}
						p{
							font-size: 12px;
						}
					}
					.location-contact{
						font-size: 12px;
						margin-top: 10px;
					}
					.location-socmed{
						font-size: 12px;
						margin-top: 15px;
						.socmed-list{
							i{
								width: 26px;
								height: 26px;
								background-size: 26px;
							}
						}
					}
				}
			}
		}
	}
}