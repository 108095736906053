input[type='submit'],
button{ cursor:pointer; }
a,
input[type='submit'],
button{
	position:relative;
	display: inline-block;
	border: none; outline: none; 
	text-decoration:none;
	transition: .2s ease;
	&.btn--primary{
		font-size: 14px;
		color: #fff;
		letter-spacing: 0;
		background-color: #000;
		border-radius: 0;
		padding: 7px 16px 5px;
		font-weight: 700;
		display: inline-block;
		&:hover{
			background-color: lighten(#000, 25%);
		}
		&.big{
			font-size: 16px;
			padding: 12px 25px 10px;
		}
	}
	&.btn--primary2{
		font-size: 14px;
		color: #fff;
		letter-spacing: 0;
		background-color: #731e22;
		border-radius: 0;
		padding: 7px 16px 5px;
		font-weight: 700;
		display: inline-block;
		&:hover{
			background-color: lighten(#731e22, 25%);
		}
		&.big{
			font-size: 16px;
			padding: 12px 25px 10px;
		}
	}
}



@media screen and (max-width: 768px){
	
}