.section{
	.section-name{
		font-family: sans-serif;
		letter-spacing: 0.5px;
		font-weight: 700;

		position: absolute;
		transform: rotate(270deg);
		left: 0;
	}
	&.mob-content{
		display: none;
		padding: 20px 15px 20px 60px;
		position: relative;
		min-height: 150px;
		font-family: sans-serif;
	}
	&.desk-content{
		display: block;
	}
	&.banner{
        .img{
        	position: relative;
        	width: 100%;
        	height: 0;
        	padding-bottom: 35%;
        	img{
        		position: absolute;
        		width: 100%;
        		height: 100%;
        		object-fit: cover;
        	}
        	.alpha59{
        		opacity: .59;
        	}
        }
    }
}

.banner-carousel{
	.carousel-slide{
		float: left;
		height: 100%;
		min-height: 1px;
	}
}

.menu-navbar{
	@extend .dtlnobel.bold;
	ul{
		li{
			font-size: 14px;
			padding: 15px 0;
			letter-spacing: 2px;
			&.active{
				a{
					color: #fff;
				}
			}
			a{
				display: inline-block;
				color: lighten(#909092, 45%);
				transition: .3s ease;
				&:hover{
					color: darken(#fff, 15%);	
				}
			}
		}
	}
}

.accordion{
	.accordion-list{
		.accordion-header{
			padding: 10px 0;
			position: relative;
			font-family: sans-serif;
			border-bottom: 2px solid #c05e62;
			transition: .3s ease;
			// &:last-child{
			// 	border-bottom: 0;
			// }
			&:after{
				content: '';
				width: 19px;
				height: 16px;
				position: absolute;
				right: 12px;
				top: 17px;
				background-image: url(/images/ico/ico-chevrondown.png);
				background-repeat: no-repeat;
				background-position: center;
				transform: rotate(0deg);
			}
			&.active{
				&:after{
					transform: rotate(180deg);
				}
			}
			h2{
				font-size: 30px;
				color: #c05e62;
				font-weight: 700;
				letter-spacing: 1px;
			}
		}
		.accordion-body{
			border-bottom: 2px solid #c05e62;
			padding: 15px 0;
		}
		&:last-child{
			.accordion-body{
				border-bottom: 0;
			}
		}
		.accordion-content{
			display: none;
		}
	}
}

.bg-patern{
	background-image: url(/images/bg-patern.jpg);
	background-repeat: repeat;
	position: relative;
}
.bg-patern2{
	background-image: url(/images/bg-patern2.jpg);
	background-repeat: repeat;
	position: relative;
}

.pagination{
	text-align: center;
	ul{
		display: flex;
		align-items: center;
		justify-content: center;
		@extend .dtlnobel.bold;
		li{
			margin: 0 5px;
			letter-spacing: 3px;
			&.active{
				color: #9e121a;
			}
		}
	}
}


@media screen and (max-width: 768px){
	.section{
		&.desk-content{
			display: none;
		}
		&.mob-content{
			display: block;
		}
	}
}

@media screen and (max-width: 520px){
	
}