.page--restaurant{
	.restaurant-content{
		display: block;
	}
	.restaurant-content-mobile{
		display: none;
	}
	.restaurant-idnty{
		position: absolute;
		top: 40%;
		left: 50px;
		color: #fff;
		.logo{
			margin-bottom: 20px;
			width: 100px;
		}
		h3{
			letter-spacing: 1.5px;
		}
	}
	.collapsible{
		color: #fff;
		.collapsible-list{
			.collapsible-header{
				background-color: #000;
				text-align: center;
				padding: 10px 0;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 70px;
				border-bottom: 0;
				position: relative;
				&:after{
					display: none;
				}
				.btn-backtomain{
					position: absolute;
					left: 50px;
					font-size: 12px;
					padding-left: 20px;
					&:before{
						content: '';
						width: 10px;
						height: 10px;
						position: absolute;
						border-top: 1px solid #fff;
						border-left: 1px solid #fff;
						transform: rotate(-45deg);
						left: 0;
						top:0;
						bottom:0;
						margin:auto;
					}
				}
				.collapsible-button{
					position: absolute;
					width: 15px;
					height: 15px;
					top: 0;
					bottom: 0;
					right: 50px;
					margin: auto;
					&:before{
						content: '';
						width: 13px;
						height: 13px;
						border-top: 1px solid #fff;
						border-left: 1px solid #fff;
						transform: rotate(45deg);
						position: absolute;
						top:0;
						bottom:0;
						margin: auto;
					}
					&.collapse{
						&:before{
							transform: rotate(225deg);
						}
					}
				}
				.title{
					font-size: 18px;
				}
			}
			.collapsible-body{
				padding: 0;
				border-bottom: 0;
				position: relative;
				.bg{
					position: absolute;
					height: 100%;
					width: 100%;
					&:before{
						content: '';
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						background-color: rgba(0,0,0,.6);
						z-index: 1;
					}
					img{
						position:absolute;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				[class*="container"]{
					position: relative;
					z-index: 2;
				}
				.row{
					padding: 50px 10px;
					.col{
						padding: 0 40px;
					}
				}
				h5{
					font-size: 16px;
					font-weight: 700;
					margin-bottom: 3px;
				}
				.information-copy{
					margin-bottom: 20px;
					&:last-child{
						margin-bottom: 0;
					}
					p{
						margin: 10px 0;
					}
					.link{
						margin: 5px 0;
					}
					ul{
						margin: 0;
						padding-left: 15px;
						li{
							list-style-type: disc;
						}
					}
					p, b, span,
					i, h1, h2,
					h3, h4, h5,
					h6{
						font-family: inherit !important;
					}
				}
				ul.socmed{
					padding: 0;
					display: flex;
					align-items: center;
					margin: 30px -10px 0;
					li{
						list-style: none;
						padding: 0 10px;
					}
				}
			}
		}
	}
	.gallery{
		display: flex;
		align-items:center;
		flex-wrap: wrap;
		// margin: -10px;
		.gallery-item{
			width: susy-span(3 of 12);
			flex-shrink: 0;
			// padding: 10px;
			a{
				position: relative;
				padding-bottom: 100%;
				display: block;
			}
			img{
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.restaurant-list-wrapper{
		min-height: 480px;
		.restaurant-list{
			.restaurant-list-item{
				height: 110px;
				display: flex;
				align-items: center;
			}
		}
	}
}

.restaurant-list-wrapper{
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 768px;
	.restaurant-list{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		font-weight: 700;
		.restaurant-list-item{
			width: susy-span(4 of 12);
			flex-shrink: 0;
			padding: 0 15px;
			font-size: 18px;
			position: relative;
			height: 120px;
			.inner{
				display: inline-block;
				position: relative;
				padding-left: 89px;
				&:hover{
					.list-item-child{
						z-index: 1;
						opacity: 1;
						visibility: visible;
					}
				}
			}
			a{
				color: #c9bca9;
				transition: .3s ease;
				&:hover{
					color: rgba(255,255,255,1);
				}
			}
			.list-item-child{
				position: absolute;
				left: 120%;
				top: -35px;
				font-size: 14px;
				min-width: 200px;
				transition: .3s ease;
				z-index: 0;
				opacity: 0;
				visibility: hidden;
				ul{
					margin: 0;
					li{
						margin-bottom: 20px;
					}
				}
				a{
					color: rgba(255,255,255,.7);
					&:hover{
						color: rgba(255,255,255,1);
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 768px){
	.page--restaurant-landing{
		& + .footer + .m-footer{
			display: none;
		}
	}
	.page--restaurant{
		.restaurant-content,
		.banner{
			display: none;
		}
		.section{
			&.mob-content{
				padding-left: 65px;
			}
		}
		.bg{
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			&:after{
				content: '';
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,.70);
				position: absolute;
				top:0;left:0;
			}
			img{
				position: absolute;
				height: 100%;
				width: 100%;
				object-fit: cover;
				opacity: .53;
			}
		}
		.restaurant-content-mobile{
			display: block;
			color: #fff;
			.section-name{
				left: -44px;
				top: 90px;
			}
			.m-res-head{
				text-align: center;
				margin-bottom: 25px;
				color: #000;
				.logo{
					margin-bottom: 15px;
					img{
						margin: auto;
					}
				}
				.title{
					font-weight: 700;
					letter-spacing: 1.2px;
					font-size: 16px;
				}
			}
			.collapsible{
				.collapsible-list{
					color: #000;
					.collapsible-header{
						height: 50px;
						border-top: 1px solid #000;
						border-bottom: 1px solid #000;
						letter-spacing: 1px;
						background-color: #fff;
						justify-content: flex-start;						
					}
					.collapsible-body{
						padding: 5px 0;
					}
					.m-information-parag{
						font-size: 14px;
						font-family: 'DTLNobelT';
						padding: 10px 0;
						h6{
							font-weight: 700;
							margin-bottom: 3px;
							font-size: 13px;
						}
					}
				}
			}
			.m-res-action{
				border-bottom: 1px solid #000;
				ul.socmed{
					li{
						margin-right: 20px;
						color: #313740;
						&:last-child{
							margin-right: 0;
						}
					}
				}
				.link{
					margin-right: 10px;
					color: #313740;
				}
			}
			.gallery{
				margin: 30px -3px;
				.gallery-item{
					width: susy-span(6 of 12);
					padding: 3px;
				}
			}
			.res-contact{
				letter-spacing: 1px;
				color: #313740;
				ul{
					margin: 0;
					border-top: 1px solid #000;
					border-bottom: 1px solid #000;
					li{
						padding: 13px 5px;
						width: susy-span(4 of 12);
						text-align: center;
					}
				}
			}
		}
	}
	.m-res-list{
		.accordion{
			.accordion-list{
				a{
					letter-spacing: 1px;
					color: #c9bca9;
					transition: .3s ease;
					&:hover{
						color: #fff;
					}
				}
				.accordion-header{
					border-bottom: 0;
					padding: 20px 0;
					&:after{
						display: none;
					}
				}
				.accordion-body{
					border-bottom: 0;
					overflow-y: hidden;
					ul{
						margin: 0;
						li{
							padding: 15px 0;
							text-align: center;
							border-bottom: 2px solid #fff;
							&:first-child{
								border-top: 2px solid #fff;
							}
						}
					}
				}
			}
		}
	}
}