.page--about{
	.section{
		&.banner{
			height: 100vh;
			min-height: 600px;
			max-height: 100%;
			background-image: linear-gradient(-180deg, #512325 0%, #6e4545 100%);
			.banner-copy{
				width: 50%;
				color: #000;
				.subtitle{
					@extend .kozukaGothic;
				}
				.title{
					@extend .bodoni;
					font-size: 58px;
					line-height: 58px;
				}
			}
			.other-img{
				width: 600px;
				height: 600px;
				border-radius: 100%;
				position: absolute;
				overflow: hidden;
				margin-bottom: -300px;
				bottom: 0;
				right: 50px;
				.banner-picture{
					position: absolute;
					top: 26px;
					width: 690px;
					max-width: unset;
					left: -55px;
					&.desk{display:block;}
					&.mob{display:none;}
				}
			}

			.banner-carousel{
				width: 100%;
				height: 100%;
				z-index: 0;
			}
			.slick-slider,
			.slick-list,
			.slick-track{
				height: 100%;
			}

			.img{
				padding: 0;
				height: 100%;
				.img-banner-desk{display: block;}
				.img-banner-mob{display: none;}
			}

			[class*="container"]{
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
			}
		}
		&.about-content{
			height: 100vh;
			min-height: 600px;
			max-height: 100%;
			position: relative;
			.bg{
				width: 100%;
				height: 100%;
				position: absolute;
				top:0;left:0;
				z-index: 0;
				img{
					position: absolute;
					width: 100%;
					height: 100%;
					object-fit: cover;
					top:0;
					left:0;
				}
			}
			.content-copy{
				background-color: rgba(0,0,0,.5);
				color: #fff;
				height: 100%;
				width: 350px;
				display: block;
				padding: 50px 10px;
				.title{
					color: #1d1918;
					margin: 30px 0;
					letter-spacing: 1.5px;
					font-weight: 700;
					font-size: 25px;
				}
				.desc{
					p{
						line-height: 21px;
					}
				}
			}
		}
	}
}


@media only screen and (max-width: 768px){
	.page--about{
		.section{
			&.banner{
				.banner-copy{
					width: 100%;
				}
				.other-img{
					width: 310px;
					height: 310px;
					bottom: 0;
					right: 0;
					left: 0;
					margin: 0 auto -150px;
					.banner-picture{
						top: 10px;
						width: 370px;
						left: -30px;
						&.mob{display:block;}
						&.desk{display:none;}
					}
				}
				.img{
					padding: 0;
					height: 100%;
					.img-banner-desk{display: none;}
					.img-banner-mob{display: block;}
				}
			}
			&.about-content-mobile{
				padding-right: 60px;
				padding-top: 50px;
				color: #fff;
				height: 100vh;
				min-height: 600px;
				.bg{
					width: 100%;
					height: 100%;
					position: absolute;
					top:0;
					left: 0;
					img{
						width: 100%;
						height: 100%;
						position: absolute;
						top:0;
						left:0;
						object-fit: cover;
					}
				}
				.section-name{
					left: -15px;
					top: 115px;
				}
				.content{
					position: relative;
				}
			}
		}
	}
}