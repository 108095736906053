/* ############ Font Awesome ############ */
@font-face {
  font-family: 'FontAwesome';
  src: url('/fonts/fontawesome/fontawesome-webfont.eot?v=4.5.0');
  src: url('/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.5.0') format('embedded-opentype'), 
       url('/fonts/fontawesome/fontawesome-webfont.woff2?v=4.5.0') format('woff2'), 
       url('/fonts/fontawesome/fontawesome-webfont.woff?v=4.5.0') format('woff'), 
       url('/fonts/fontawesome/fontawesome-webfont.ttf?v=4.5.0') format('truetype'), 
       url('/fonts/fontawesome/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Material Design Icon */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/material-design-icons/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url('/fonts/material-design-icons/MaterialIcons-Regular.woff2') format('woff2'),
       url('/fonts/material-design-icons/MaterialIcons-Regular.woff') format('woff'),
       url('/fonts/material-design-icons/MaterialIcons-Regular.ttf') format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 16px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}


/* ############ Neusa ############ */
@font-face {
    font-family: 'DTLNobelT Italic';
    src: url('/fonts/dtl_nobel/DTLNobelT-Italic.eot');
    src: url('/fonts/dtl_nobel/DTLNobelT-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/dtl_nobel/DTLNobelT-Italic.woff2') format('woff2'),
        url('/fonts/dtl_nobel/DTLNobelT-Italic.woff') format('woff'),
        url('/fonts/dtl_nobel/DTLNobelT-Italic.ttf') format('truetype'),
        url('/fonts/dtl_nobel/DTLNobelT-Italic.svg#DTLNobelT-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DTLNobelT CondensedBold';
    src: url('/fonts/dtl_nobel/DTLNobelT-CondensedBold.eot');
    src: url('/fonts/dtl_nobel/DTLNobelT-CondensedBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/dtl_nobel/DTLNobelT-CondensedBold.woff2') format('woff2'),
        url('/fonts/dtl_nobel/DTLNobelT-CondensedBold.woff') format('woff'),
        url('/fonts/dtl_nobel/DTLNobelT-CondensedBold.ttf') format('truetype'),
        url('/fonts/dtl_nobel/DTLNobelT-CondensedBold.svg#DTLNobelT-CondensedBold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DTLNobelT LightItalic';
    src: url('/fonts/dtl_nobel/DTLNobelT-LightItalic.eot');
    src: url('/fonts/dtl_nobel/DTLNobelT-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/dtl_nobel/DTLNobelT-LightItalic.woff2') format('woff2'),
        url('/fonts/dtl_nobel/DTLNobelT-LightItalic.woff') format('woff'),
        url('/fonts/dtl_nobel/DTLNobelT-LightItalic.ttf') format('truetype'),
        url('/fonts/dtl_nobel/DTLNobelT-LightItalic.svg#DTLNobelT-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'DTLNobelT CondensedRegular';
    src: url('/fonts/dtl_nobel/DTLNobelT-CondensedRegular.eot');
    src: url('/fonts/dtl_nobel/DTLNobelT-CondensedRegular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/dtl_nobel/DTLNobelT-CondensedRegular.woff2') format('woff2'),
        url('/fonts/dtl_nobel/DTLNobelT-CondensedRegular.woff') format('woff'),
        url('/fonts/dtl_nobel/DTLNobelT-CondensedRegular.ttf') format('truetype'),
        url('/fonts/dtl_nobel/DTLNobelT-CondensedRegular.svg#DTLNobelT-CondensedRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DTLNobelT Light';
    src: url('/fonts/dtl_nobel/DTLNobelT-Light.eot');
    src: url('/fonts/dtl_nobel/DTLNobelT-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/dtl_nobel/DTLNobelT-Light.woff2') format('woff2'),
        url('/fonts/dtl_nobel/DTLNobelT-Light.woff') format('woff'),
        url('/fonts/dtl_nobel/DTLNobelT-Light.ttf') format('truetype'),
        url('/fonts/dtl_nobel/DTLNobelT-Light.svg#DTLNobelT-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DTLNobelT Bold';
    src: url('/fonts/dtl_nobel/DTLNobelT-Bold.eot');
    src: url('/fonts/dtl_nobel/DTLNobelT-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/dtl_nobel/DTLNobelT-Bold.woff2') format('woff2'),
        url('/fonts/dtl_nobel/DTLNobelT-Bold.woff') format('woff'),
        url('/fonts/dtl_nobel/DTLNobelT-Bold.ttf') format('truetype'),
        url('/fonts/dtl_nobel/DTLNobelT-Bold.svg#DTLNobelT-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DTLNobelT Euro';
    src: url('/fonts/dtl_nobel/DTLNobelT-Euro.eot');
    src: url('/fonts/dtl_nobel/DTLNobelT-Euro.eot?#iefix') format('embedded-opentype'),
        url('/fonts/dtl_nobel/DTLNobelT-Euro.woff2') format('woff2'),
        url('/fonts/dtl_nobel/DTLNobelT-Euro.woff') format('woff'),
        url('/fonts/dtl_nobel/DTLNobelT-Euro.ttf') format('truetype'),
        url('/fonts/dtl_nobel/DTLNobelT-Euro.svg#DTLNobelT-Euro') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DTLNobelT';
    src: url('/fonts/dtl_nobel/DTLNobelT.eot');
    src: url('/fonts/dtl_nobel/DTLNobelT.eot?#iefix') format('embedded-opentype'),
        url('/fonts/dtl_nobel/DTLNobelT.woff2') format('woff2'),
        url('/fonts/dtl_nobel/DTLNobelT.woff') format('woff'),
        url('/fonts/dtl_nobel/DTLNobelT.ttf') format('truetype'),
        url('/fonts/dtl_nobel/DTLNobelT.svg#DTLNobelT') format('svg');
    font-weight: normal;
    font-style: normal;
}



/* ############ Helvetica ############ */
@font-face {
    font-family: 'Helvetica Neue LT Std Italic';
    src: url('/fonts/helvetica_neue/HelveticaNeueLTStd-MdCnO.eot');
    src: url('/fonts/helvetica_neue/HelveticaNeueLTStd-MdCnO.eot?#iefix') format('embedded-opentype'),
        url('/fonts/helvetica_neue/HelveticaNeueLTStd-MdCnO.woff2') format('woff2'),
        url('/fonts/helvetica_neue/HelveticaNeueLTStd-MdCnO.woff') format('woff'),
        url('/fonts/helvetica_neue/HelveticaNeueLTStd-MdCnO.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Neue LT Std Regular';
    src: url('/fonts/helvetica_neue/HelveticaNeueLTStd-MdCn.eot');
    src: url('/fonts/helvetica_neue/HelveticaNeueLTStd-MdCn.eot?#iefix') format('embedded-opentype'),
        url('/fonts/helvetica_neue/HelveticaNeueLTStd-MdCn.woff2') format('woff2'),
        url('/fonts/helvetica_neue/HelveticaNeueLTStd-MdCn.woff') format('woff'),
        url('/fonts/helvetica_neue/HelveticaNeueLTStd-MdCn.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Std Bold';
    src: url('/fonts/helvetica_neue/HelveticaNeueLTStd-BdCn.eot');
    src: url('/fonts/helvetica_neue/HelveticaNeueLTStd-BdCn.eot?#iefix') format('embedded-opentype'),
        url('/fonts/helvetica_neue/HelveticaNeueLTStd-BdCn.woff2') format('woff2'),
        url('/fonts/helvetica_neue/HelveticaNeueLTStd-BdCn.woff') format('woff'),
        url('/fonts/helvetica_neue/HelveticaNeueLTStd-BdCn.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Std Black';
    src: url('/fonts/helvetica_neue/HelveticaNeueLTStd-HvCn.eot');
    src: url('/fonts/helvetica_neue/HelveticaNeueLTStd-HvCn.eot?#iefix') format('embedded-opentype'),
        url('/fonts/helvetica_neue/HelveticaNeueLTStd-HvCn.woff2') format('woff2'),
        url('/fonts/helvetica_neue/HelveticaNeueLTStd-HvCn.woff') format('woff'),
        url('/fonts/helvetica_neue/HelveticaNeueLTStd-HvCn.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

/* ############ PT Sans ############ */
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');


/* ############ Bodoni ############ */
@font-face {
    font-family: 'Bodoni';
    src: url('/fonts/bodoni/BodoniSvtyTwoSCITCTT-Book.eot');
    src: url('/fonts/bodoni/BodoniSvtyTwoSCITCTT-Book.eot?#iefix') format('embedded-opentype'),
        url('/fonts/bodoni/BodoniSvtyTwoSCITCTT-Book.woff2') format('woff2'),
        url('/fonts/bodoni/BodoniSvtyTwoSCITCTT-Book.woff') format('woff'),
        url('/fonts/bodoni/BodoniSvtyTwoSCITCTT-Book.ttf') format('truetype'),
        url('/fonts/bodoni/BodoniSvtyTwoSCITCTT-Book.svg#BodoniSvtyTwoSCITCTT-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}


/* ############ Kozuka ############ */
@font-face {
    font-family: 'Kozuka Gothic Regular';
    src: url('/fonts/kozuka/KozGoPr6N-Regular-AlphaNum.eot');
    src: url('/fonts/kozuka/KozGoPr6N-Regular-AlphaNum.eot?#iefix') format('embedded-opentype'),
        url('/fonts/kozuka/KozGoPr6N-Regular-AlphaNum.woff2') format('woff2'),
        url('/fonts/kozuka/KozGoPr6N-Regular-AlphaNum.woff') format('woff'),
        url('/fonts/kozuka/KozGoPr6N-Regular-AlphaNum.ttf') format('truetype'),
        url('/fonts/kozuka/KozGoPr6N-Regular-AlphaNum.svg#KozGoPr6N-Regular-AlphaNum') format('svg');
    font-weight: normal;
    font-style: normal;
}


/* ############ Sinkai ############ */
@font-face {
    font-family: 'Arphic Gyokaisho Heavy JIS';
    src: url('/fonts/sinkai/SinkaiEG-Heavy-SJIS.eot');
    src: url('/fonts/sinkai/SinkaiEG-Heavy-SJIS.eot?#iefix') format('embedded-opentype'),
        url('/fonts/sinkai/SinkaiEG-Heavy-SJIS.woff2') format('woff2'),
        url('/fonts/sinkai/SinkaiEG-Heavy-SJIS.woff') format('woff'),
        url('/fonts/sinkai/SinkaiEG-Heavy-SJIS.ttf') format('truetype'),
        url('/fonts/sinkai/SinkaiEG-Heavy-SJIS.svg#SinkaiEG-Heavy-SJIS') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Arphic PGyokaisho Heavy JIS';
    src: url('/fonts/sinkai/SinkaiEGP-Heavy-SJIS.eot');
    src: url('/fonts/sinkai/SinkaiEGP-Heavy-SJIS.eot?#iefix') format('embedded-opentype'),
        url('/fonts/sinkai/SinkaiEGP-Heavy-SJIS.woff2') format('woff2'),
        url('/fonts/sinkai/SinkaiEGP-Heavy-SJIS.woff') format('woff'),
        url('/fonts/sinkai/SinkaiEGP-Heavy-SJIS.ttf') format('truetype'),
        url('/fonts/sinkai/SinkaiEGP-Heavy-SJIS.svg#SinkaiEGP-Heavy-SJIS') format('svg');
    font-weight: 900;
    font-style: normal;
}

