.page--news{
	
}

.news-content{
	.content-copy{
		.title{
			@extend .dtlnobel.bold;
			font-size: 16px;
			margin-bottom: 5px;
		}
		.date{
			font-size: 20px;
		}
		p{
			font-size: 16px;
			margin-top: 20px;
			line-height: 21px;
		}
		.btn-readmore{
			@extend .dtlnobel.bold;
		}
	}
	.content-thumb{
		padding: 10px;
		background-color: transparent;
		border-radius: 6px;
		.img{
			
		}
		.thumb-video{
			position: relative;
			&:before{
				content: '';
				width: 70px;
				height: 70px;
				background-image: url(/images/ico/ico-play.png);
				background-repeat: no-repeat;
				background-position: center;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				opacity: .9;
			}
			&:hover{
				&:before{
					opacity: 1;
				}
			}
		}
	}
	.news-cat{
		font-weight: 700;
	}
	.content-wrapper{
		padding: 40px 0;
	}
	.border-style{
		border: 3px solid #9e121a;
		position: relative;
		&:before,
		&:after,{
			content: '';
			width: 40px;
			height: 39px;
			background-image: url(/images/border-tip.png);
			background-repeat: no-repeat;
			position: absolute;
		}
	}
	.content-copy{
		.btn-readmore{
			padding: 10px 20px;
			border-radius: 6px;
			margin-top: 30px;
		}
	}
	.news-highlight{
		color: #fff;
		padding: 40px 0 0;
		position: relative;
		&:before{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top:0;
			left:0;
			background-color: rgba(130, 33, 38, 0.8);
		}
		.border-style{
			border-bottom: 0;
			&:before{
				top: -3px;
				left: -3px;
			}
			&:after{
				top: -3px;
				right: -3px;
				transform: scaleX(-1);
			}
		}
		.content-copy{
			.btn-readmore{
				border: 1px solid #fff;
				&:hover{
					background-color: #fff;
					color: #000;
				}
			}
		}
		.content-thumb{
			background-color: #fff;
			.img{
				
			}
		}
		.news-cat{
			margin-bottom: 10px;
		}
		.news-highlight-list{
			.row{
				margin-bottom: 50px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
	.news-all{
		padding: 0 0 40px;
		.border-style{
			border-top: 0;
			&:before{
				background-image: url(/images/border-tip2.png);
				bottom: -3px;
				left: -3px;
				// transform: scaleY(-1);
			}
			&:after{
				background-image: url(/images/border-tip2.png);
				bottom: -3px;
				right: -3px;
				// transform: scaleX(-1) scaleY(-1);
				transform: scaleX(-1);
			}
		}
		.content-copy{
			.btn-readmore{
				border: 1px solid #000;
				&:hover{
					background-color: #000;
					color: #fff;
				}
			}
		}
		.news-list{
			.news-list-item{
				margin-bottom: 50px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
	.news-detail{
		padding: 50px 0;
		.content{
			padding: 70px 40px;
		}
		.border-style{
			&:before,
			&:after{
				display: none;
			}
			.tip-top{
				&:before,
				&:after,{
					content: '';
					width: 40px;
					height: 39px;
					background-image: url(/images/border-tip2.png);
					background-repeat: no-repeat;
					position: absolute;
				}
				&:before{
					top: -3px;
					left: -3px;
					transform: scaleY(-1);
				}
				&:after{
					top: -3px;
					right: -3px;
					transform: scaleX(-1) scaleY(-1);
				}
			}
			.tip-bot{
				&:before,
				&:after,{
					content: '';
					width: 40px;
					height: 39px;
					background-image: url(/images/border-tip2.png);
					background-repeat: no-repeat;
					position: absolute;
				}
				&:before{
					bottom: -3px;
					left: -3px;
				}
				&:after{
					bottom: -3px;
					right: -3px;
					transform: scaleX(-1);
				}
			}
		}
		.news-detail-content{
			.title{
				margin-bottom: 40px;
			}
			.date{
				display: block;
				margin-bottom: 15px;
			}
			.img{
				margin-bottom: 15px;
			}
			.parag{
				p{
					line-height: 21px;
				}
				ul{
					padding-left: 15px;
					list-style-type: disc;
				}
				p, b, span,
				i, h1, h2,
				h3, h4, h5,
				h6{
					font-family: inherit !important;
				}
				a{
					text-decoration: underline !important;
					&:hover{
						text-decoration: none !important;
					}
				}
			}
		}
		.other-news-content{
			margin-top: 30px;
			.title{
				margin-bottom: 30px;
			}
			.row{
				margin-left: -10px;
				margin-right: -10px;
				.col{
					padding: 0 10px;
					.name{
						margin-top: 20px;
						font-size: 16px;
					}
					.thumb-video{
						position: relative;
						.img{
							position: relative;
							&:before{
								content: '';
								width: 70px;
								height: 70px;
								background-image: url(/images/ico/ico-play.png);
								background-repeat: no-repeat;
								background-position: center;
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								margin: auto;
								opacity: .9;
							}
						}
						&:hover{
							.img{
								&:before{
									opacity: 1;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 768px){
	.page--news{
		background-color: #56292a;
		color: #fff;
		.section{
			&.mob-content{
				padding: 20px 60px 20px;
			}
		}
		.news-content-mobile{
			.content{padding: 0;}
			.section-name{
				top: 40px;
			}
			.img{
				padding: 5px;
				background-color: #fff;
				border-radius: 3px;
				margin-bottom: 10px;
			}
			.text{
				font-size: 13px;
				font-weight: 700;
				padding-right: 10px;
			}
			.date{
				background-color: #000;
				text-align: right;
				padding: 5px;
				height: 100%;
				min-height: 56px;
				display: block;
			}
		}
		.m-news-highlight{
			.news-highlight-mobile-list{
				.news-highlight-item{
					margin-bottom: 20px;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}
		.m-news-all{
			background-color: #fff;
			color: #313740;
			.img{
				padding: 0;
			}
			.date{
				color: #fff;
			}
			.m-news-copy{
				margin-bottom: 10px;
			}
			p{
				font-size: 12px;
				line-height: 18px;
			}
			.btn-readmore{
				font-weight: 700;
				margin-top: 20px;
			}
		}
		.news-all-list{
			margin: -10px;
			.news-all-list-item{
				padding: 10px;
				border: 1px solid #c7c6c6;
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
		.news-detail-thumbnail-mobile{
			.section-name{
				top: 135px;
				z-index: 1;
			}
			.img{
				margin: -20px -60px -20px;
				padding: 0;
				position: relative;
				padding-bottom: 95%;
				img{
					position: absolute;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
		.news-detail-content-mobile{
			background-color: #fff;
			color: #000;
			.date{
				position: absolute;
				right: 15px;
				top:0;
				color: #fff;
				height: auto;
			}
			.title{
				margin-bottom: 15px;
			}
			.parag{
				font-size: 12px;
				p{
					line-height: 18px;
					font-size: inherit;
					margin: 10px 0;
				}
			}
		}
		.news-detail-suggest{
			padding: 0 20px 20px !important;;
			background-color: #fff;
			.suggest-list{
				padding: 10px 0;
				border-bottom: 2px solid #bf1e2e;
				&:first-child{
					border-top: 2px solid #bf1e2e;
				}
				.img{
					padding: 0;
					margin: 0;
					flex-shrink: 0;
					margin-right: 10px;
				}
				.text{
					color: #000;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.title{
						font-size: 14px;
					}
					.link{
						font-size: 14px;
						margin-top: 10px;
						letter-spacing: 1.3px;
					}
				}
			}
		}
	}
}