.header--promo{
	width: 100%;
  	height: 50px;
  	background-color: #750000;
  	position: fixed;
	z-index: 20;
	.header-back{
	    padding: 11px 15px 11px 0px;
	    width: 50px;
	    color: #ffffff;
	    float:left;
	    margin-left: -8px;
	}
	.header-title{
		color: #ffffff;
    	padding-top: 14px;
    	padding-right: 50px;
	}
}
.promo--body{
    background-color: #f1f1ee;
    min-height: 100vh;
}
.content-promo{
    padding: 0 12px;
    @media screen and (max-width: 768px){
    	padding: 0px 16px;
    }
}

.page--promo{
	background-color: #f1f1ee;
	padding-top: 64px;
	.promo-list{
		display: flex;
  		flex-wrap: wrap;
  		.promo-list-cont{
  			margin: 1%;
  			border-radius: 8px;
  			background: #FFFFFF;
  			box-shadow: 0px 8px 14px rgba(224, 229, 238, 0.5);
  			width: 48%;
  			.promo-list-item {
  				display: block;
  				.img{
  			    position: relative;
    			width: 100%;
    			height:172px;
    			img{
    				border-radius: 8px 8px 0 0;
    				position: absolute;
				    width: 100%;
				    height: 100%;
				    object-fit: cover;
					}
  				}
  				.copy {
				    margin: 12px 10px 5px;
				    min-height: 42px;
				    font-size: 16px;
					line-height: 21px;
					letter-spacing: -0.171429px;
					color: #262626;
	  			}
	  			.desc{
	  				font-size: 13px;
					line-height: 20px;
					letter-spacing: -0.26px;
					color: #ADAFB3;
					margin: 0px 10px 12px;
	  			}
	  		}
	  		@media screen and (max-width: 768px){
				width: 100%;
				.promo-list-item{
					width: 100%;
	  				height: auto;
					.img{
					    height: 0;
    					padding-bottom: 46%;
    				}
				}
			}
  		}
	}
	.promo-info{
		display: flex;
		flex-wrap: wrap;
		.promo-banner{
			border-radius: 8px;
  			background: #ffffff;
  			width: calc((100% - 10px)*414/766);
  			height: 192px;
  			.img{
  				border-radius: 8px;
  				height: 192px;
				padding: 0;
				img{
					position: absolute;
				    width: 100%;
				    height: 100%;
				    object-fit: cover;
				    border-radius: 8px;
				}
			}
			.slick-dots{
				bottom: 10px;
				text-align: left;
				z-index: 10;
				li{
					margin: 2px 0px 2px 4px;
					width: 10px;
					button:before {
					color: #fff !important;
					font-size: 30px;
					}
				}
			}
		}
		.promo-condition{
			    border-radius: 8px;
			    background: #ffffff;
			    float: left;
			    width: calc((100% - 10px)*348/766);
			    height: 192px;
			    margin: 0px 0px 0px 10px;
			    padding: 0 14px;
  			.condition-detail{
  				height: 50%;
			    width: 100%;
			    display: block;
			    text-align: left;
			    padding: 29px 0;
			    img{
			    	float: left;
			    	margin-right: 8px;

			    }
			    .title{
			    	color: #A18167;
			    	margin-bottom: 6px;
			    	font-size: 10px;
					line-height: 12px;
					letter-spacing: 0.4px;
					display: inline-block;
    				width: 83%;
			    }
			    .description{
			    	font-size: 12px;
					line-height: 14px;
					letter-spacing: -0.24px;
					color: #5A5F66;
			    }
  			}
  			.top-stroke{
  				border-top: 4px dotted #f1f3ee;
  			}
		}

		@media screen and (max-width: 768px){
  			.promo-banner{
	  			width: 100%;
	  			height: auto;
	  			.img{
	  				height: 0;
					padding-bottom: 46%;
				}
			}
			.promo-condition{
				margin: 16px 0px 0px 0px;
	  			width: 100%;
			}
		}
	}
	.promo-detail-title{
	    background: #FFFFFF;
		border-radius: 8px;
	    margin: 16px 0;
	    height: fit-content;
	    padding: 20px 16px 16px 16px;
	    .title{
	    	font-size: 24px;
			line-height: 24px;
			letter-spacing: -0.48px;
			color: #262B33;
	    }
	    .title-note{
	    	padding-top: 14px;
	    	font-size: 16px;
			line-height: 22px;
			letter-spacing: -0.32px;
			color: #707070;
	    }
	}
	.promo-detail{
		background: #FFFFFF;
		border-radius: 8px;
		padding: 16px;
		text-align: left;
		.promo-detail-content{
			.content-restaurant{
				.title{
					font-size: 16px;
					line-height: 24px;
					letter-spacing: -0.32px;
					color: #262B33;
					display: inline-block;
    				width: 100%;
				}
				ul{
					color: #7f7f7f;
					margin: 6px 0px 39px 0px;
					li{
						font-size: 16px;
						letter-spacing: -0.32px;
						color: #707070;
						border-bottom: 3px dotted #F1F0F5;
						width: 50%;
						padding: 7px 0;
					}
				}
			}
			.content-toc{
				.title{
					font-size: 16px;
					line-height: 24px;
					letter-spacing: -0.32px;
					color: #262B33;
					display: inline-block;
    				width: 100%;
				}
				.content-condition{
					letter-spacing: -0.32px;
					color: #707070 !important;
					ul{
						padding-left: 19px;
						list-style-type: disc;
						li{
							font-size: 16px;
							line-height: 24px;
						}
					}
					ol{
						padding-left: 18px;
					}
					p{
						font-size: 16px;
						line-height: 24px;
					}
					p, b, span,
					i, h1, h2,
					h3, h4, h5,
					h6{
						font-family: inherit !important;
						margin: 5px 0;
					}
					a{
						text-decoration: underline !important;
						&:hover{
							text-decoration: none !important;
						}
					}
					.img{
						margin-bottom: 15px;
					}
				}
				
			}

			@media screen and (max-width: 768px){
				.content-restaurant{
					ul li{
						width: 100%;
					}
				}
			}		
		}
	}
}