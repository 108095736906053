// .header{
//     position: fixed;
//     width: 100%;
//     z-index: 100;
//     display: flex;
//     justify-content: space-between;
//     .header-logo{
//         padding: 30px 0 0 50px;
//         a{display:block;}
//         .logo-kawano{
//             max-width: 80px;
//         }
//     }
//     .menu-navbar{
//         visibility: hidden;
//         opacity: 0;
//         position: absolute;
//         right: 0;
//         top: 40px;
//         transition: .3s ease;
//     }
//     .header-menu{
//         position: absolute;
//         right: 50px;
//         top: 30px;
//         &.black{
//             .menu-button{
//                 span{
//                     background-color: #000;
//                 }
//             }
//         }
//         .menu-button{
//             width: 24px;
//             height: 35px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             margin: 0 0 0 auto;
//             cursor: pointer;
//             position: relative;
//             span{
//                 width: 2px;
//                 height: 100%;
//                 background-color: #fff;
//                 border-radius: 30px;
//                 display: block;
//                 position: absolute;
//                 transition: .2s ease;
//                 &:first-child,
//                 &:last-child{
//                     height: 80%;
//                     position: absolute;
//                     top:0;
//                     bottom: 0;
//                     margin: auto;
//                 }
//                 &:first-child{
//                     left: 0;
//                     opacity: 1;
//                 }
//                 &:nth-child(2){}
//                 &:nth-child(3){}
//                 &:last-child{
//                     right: 0;
//                     opacity: 1;
//                 }
//             }
//         }
//         .menu-navbar{
//             text-align: right;
//             position: absolute;
//             ul{
//                 margin: 0;
//                 li{
//                     text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4)   ;
//                 }
//             }
//         }
//         &.active{
//             .menu-button{
//                 span{
//                     &:first-child{
//                         left: 45%;
//                         height: 0;
//                         opacity: 0;
//                     }
//                     &:last-child{
//                         right: 45%;
//                         height: 0;
//                         opacity: 0;
//                     }
//                     &:nth-child(2){
//                         transform: rotate(-45deg);
//                         height: 90%;
//                     }
//                     &:nth-child(3){
//                         transform: rotate(45deg);
//                         height: 90%;
//                     }
//                 }
//             }
//             .menu-navbar{
//                 visibility: visible;
//                 opacity: 1;
//             }
//         }
//     }
// }
.header-logo{
    padding: 30px 0 0 50px;
    position: fixed;
    z-index: 10;
    a{display:block;}
    .logo-kawano{
        max-width: 80px;
    }
}
.menu-navbar{
    visibility: hidden;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 40px;
    transition: .3s ease;
}
.header-menu{
    position: fixed;
    right: 50px;
    top: 30px;
    z-index: 10;
    &.black{
        .menu-button{
            span{
                background-color: #000;
            }
        }
        .menu-navbar{
            ul{
                li{
                    text-shadow: 0 2px 2px rgba(0,0,0,.2);
                    a{
                        color: #000;
                    }
                }
            }
        }
    }
    .menu-button{
        width: 24px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 auto;
        cursor: pointer;
        position: relative;
        span{
            width: 2px;
            height: 100%;
            background-color: #fff;
            border-radius: 30px;
            display: block;
            position: absolute;
            transition: .2s ease;
            &:first-child,
            &:last-child{
                height: 80%;
                position: absolute;
                top:0;
                bottom: 0;
                margin: auto;
            }
            &:first-child{
                left: 0;
                opacity: 1;
            }
            &:nth-child(2){}
            &:nth-child(3){}
            &:last-child{
                right: 0;
                opacity: 1;
            }
        }
    }
    .menu-navbar{
        text-align: right;
        position: absolute;
        ul{
            margin: 0;
            li{
                text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4)   ;
            }
        }
    }
    &.active{
        .menu-button{
            span{
                &:first-child{
                    left: 45%;
                    height: 0;
                    opacity: 0;
                }
                &:last-child{
                    right: 45%;
                    height: 0;
                    opacity: 0;
                }
                &:nth-child(2){
                    transform: rotate(-45deg);
                    height: 90%;
                }
                &:nth-child(3){
                    transform: rotate(45deg);
                    height: 90%;
                }
            }
        }
        .menu-navbar{
            visibility: visible;
            opacity: 1;
        }
    }
}


@media only screen and (max-width: 1360px){
    // .header{
    //     .header-logo{
    //         .logo-kawano{
    //             max-width: 55px;
    //         }
    //     }
    //     .header-menu{
    //         .menu-button{
    //             span{
    //                 // height: 30px;
    //             }
    //         }
    //     }     
    // }
    .header-logo{
        .logo-kawano{
            max-width: 55px;
        }
    }
}

@media only screen and (max-width: 768px){
    // .header{
    //     .header-logo{
    //         padding: 30px 0 0 25px;
    //         .logo-kawano{
    //             max-width: 42px;
    //         }
    //     }
    //     .header-menu{
    //         right: 0;
    //         top: 0;
    //         .menu-button{
    //             position: absolute;
    //             right: 25px;
    //             top: 30px;
    //             z-index: 10;
    //             span{
    //                 // background-color: #000;
    //                 &:before,
    //                 &:after{
    //                     background-color: #000;
    //                 }
    //             }
    //         }
    //         .menu-navbar{
    //             position: absolute;;
    //             height: 100vh;
    //             width: 100vw;
    //             top:0;
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;
    //             text-align: center;
    //             z-index: 3;
    //             background-image: url(/images/banner/bg-menu-mobile.jpg);
    //             background-repeat: no-repeat;
    //             background-size: cover;
    //             &:before{
    //                 content: '';
    //                 width: 100%;
    //                 height: 100%;
    //                 position: absolute;
    //                 top: 0;
    //                 left: 0;
    //                 background-color: rgba(0,0,0,.6);
    //             }
    //             ul{
    //                 position: relative;
    //                 li{
    //                     a{
    //                         color: #fff;
    //                     }
    //                 }
    //             }
    //         }
    //     }     
    // }
    .header-logo{
        padding: 30px 0 0 25px;
        .logo-kawano{
            max-width: 42px;
        }
    }
    .header-menu{
        right: 0;
        top: 0;
        &.black{
            &.active{
                .menu-button{
                    span{
                        background-color: #fff;
                    }
                }
                .menu-navbar{
                    ul{
                        li{
                            a{
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
        .menu-button{
            position: absolute;
            right: 25px;
            top: 30px;
            z-index: 10;
            span{
                &:before,
                &:after{
                    background-color: #000;
                }
            }
        }
        .menu-navbar{
            position: absolute;;
            height: 100vh;
            width: 100vw;
            top:0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 3;
            background-image: url(/images/banner/bg-menu-mobile.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            &:before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0,0,0,.6);
            }
            ul{
                position: relative;
                li{
                    a{
                        color: #fff;
                    }
                }
            }
        }
    }
}