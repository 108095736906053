/**
 * Simple fade transition,
 */
.mfp-fade.mfp-bg {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out; 
	-moz-transition: all 0.15s ease-out; 
	transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out; 
	-moz-transition: all 0.15s ease-out; 
	transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
}

// .mfp-fade{
// 	&.mfp-bg{
// 		opacity: 0;
// 		-webkit-transition: all 0.15s ease-out; 
// 		-moz-transition: all 0.15s ease-out; 
// 		transition: all 0.15s ease-out;
// 		background-color: rgba(255,255,255,9.5);
// 		&.mfp-ready{
// 			opacity: 0.95;
// 		}
// 		&.mfp-removing{
// 			opacity: 0;
// 		}
// 	}
// 	&.mfp-mobile{
// 		&.mfp-wrap{
// 			.mfp-content{
// 				max-width: 960px;
// 			}
// 		}
// 	}
// 	&.mfp-wrap{
// 		&.mfp-ready {
// 			-webkit-backface-visibility: hidden;
// 			-webkit-transform: translateZ(0);
// 			-webkit-overflow-scrolling: touch;
// 		}
// 		.mfp-content{
// 			opacity: 0;
// 			-webkit-transition: all 0.15s ease-out; 
// 			-moz-transition: all 0.15s ease-out; 
// 			transition: all 0.15s ease-out;
// 			max-width: 1140px;
// 			.mfp-figure{
// 				&:after{
// 					box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
// 				}
// 				.mfp-title{
// 					margin: 15px 0 5px;
// 				}
// 			}
// 			.mfp-iframe-scaler{
// 				overflow: visible;
// 				padding: 0;
// 				height: auto;
// 			}
// 			.iframe{
// 				width: 100%;
// 				height: 0;
// 				overflow: hidden;
// 				padding-top: 56.25%;
// 				position: relative;
// 			}
// 			.mfp-close{
// 				@extend .medium;
// 				font-size: 20px;
// 				color: #86879c;
// 				letter-spacing: 2.67px;
// 				text-transform: uppercase;
// 				cursor: pointer;
// 				display: inline-block;
// 				width: auto;
// 			}
// 			.mfp-title{
// 				@extend .wide-bold;
// 				position: relative;
// 				color: #313740;
// 				letter-spacing: 0.3px;
// 				line-height: 42px;
// 				font-size: 32px;
// 				margin: 24px 0 5px;
// 			}
// 			.mfp-description{
// 				color: rgba(0,0,0,.5);
// 				letter-spacing: 0.15px;
// 				line-height: 24px;
// 				width: 100%;
// 				padding-right: 20px;
// 			}
// 			.desc__wrapper{
// 				display: flex;
// 				justify-content: space-between;
// 				align-items: flex-start;
// 			}			
// 		}
// 		&.mfp-ready{
// 			.mfp-content{
// 				opacity: 1;
// 			}
// 		}
// 		&.mfp-removing{
// 			.mfp-content{
// 				opacity: 0;
// 			}
// 		}
// 	}
// 	&.mfp-gallery{
// 		max-width: 1140px;
// 		margin: auto;
// 		right: 0;
// 		overflow: visible !important;	
// 		.mfp-close{display: none;}	
// 		.mfp-container{
// 			.mfp-close{display: block;}
// 		}
// 		.mfp-content{
// 			.mfp-title{
// 				@extend .medium;
// 				font-size: 18px;
// 				line-height: 23px;
// 				padding-right: 50px;
// 			}
// 			.mfp-counter{
// 				@extend .medium;
// 				font-size: 18px;
// 				color: #313740;
// 				letter-spacing: 0;
// 				line-height: 23px;
// 				opacity: .5;
// 				margin-top: 15px;
// 			}
// 		}
// 		.mfp-bottom-bar-control{
// 			.share-video{
// 				position: absolute;
// 				right: 0;
// 			}
// 		}
// 		.mfp-arrow{
// 			width: 25px;
// 			height: 25px;
// 			margin: 0;
// 			&:before{
// 				border-top: 2px solid #313740;
// 				border-left: 2px solid #313740;
// 				border-bottom: 0;
// 				border-right: 0;
// 				margin: 0;
// 				width: 25px;
// 				height: 25px;
// 			}
// 			&:after{ 
// 				border: 0px solid transparent;
// 			}
// 			&.mfp-arrow-right{
// 				right: -35px;
// 				&:before{
// 					transform: rotate(135deg);
// 				}
// 			}
// 			&.mfp-arrow-left{
// 				left: -35px;
// 				&:before{
// 					transform: rotate(-45deg);
// 				}
// 			}
// 		}
// 	}
// }

// .iphone{
// 	.overlay-fix{
// 		overflow: hidden;
// 	}
// }

// @media screen and (max-width: 900px){
// 	.mfp-fade{
// 		&.mfp-wrap{
// 			.mfp-container{
// 				padding-left: 0px;
// 				padding-right: 0px;
// 				&:before{
// 					// vertical-align: top;
// 				}
// 			}
// 			.mfp-content{
// 				.mfp-title{
// 					line-height: 32px;
// 					font-size: 22px;
// 					padding: 0 6px;
// 				}
// 				.mfp-counter{
// 					right: 5px;
// 				}
// 				.desc__wrapper{
// 					padding: 0 6px;
// 				}
// 			}
// 		}
// 		&.mfp-gallery{
// 			.mfp-bottom-bar-control{
// 				.share-video{
// 					right: 6px;
// 				}
// 			}
// 			.mfp-arrow{
// 				&.mfp-arrow-left{left: 5%;}
// 				&.mfp-arrow-right{right: 5%;}
// 			}
// 		}
// 	}
// }

// @media screen and (max-width: 520px){
// 	.mfp-fade{
// 		&.mfp-wrap{
// 			.mfp-content{
// 				.mfp-close{
// 					font-size: 15px;;
// 				}
// 			}
// 		}
// 		&.mfp-gallery{
// 			.mfp-content{
// 				.mfp-bottom-bar{
// 					padding: 0 5px;
// 				}
// 				.mfp-title{
// 					font-size: 15px;
// 					line-height: 20px;
// 					padding-right: 50px;
// 				}
// 				.mfp-counter{
// 					margin-top: 10px;
// 					font-size: 15px;
// 				}
// 				.mfp-figure{
// 					.mfp-title{
// 						margin: 10px 0 5px;
// 					}	
// 				}
// 			}
// 		}
// 	}
// }

// @media screen and (min-width: 700px)
// and (max-width: 1023px)
// and (orientation: landscape){
//     .mfp-fade{
//     	&.mfp-gallery{
//     		overflow: hidden auto !important;
//     	}
//     }
// }

// @media screen and (max-height: 1050px)
// and (orientation: landscape){
// 	.mfp-fade{
// 		&.mfp-gallery{
// 			.mfp-img{max-height: 900px;}
// 		}
// 	}
// }

// @media screen and (max-height: 900px)
// and (orientation: landscape){
// 	.mfp-fade{
// 		&.mfp-gallery{
// 			.mfp-img{max-height: 760px;}
// 		}
// 	}
// }

// @media screen and (max-height: 720px)
// and (orientation: landscape){
// 	.mfp-fade{
// 		&.mfp-gallery{
// 			.mfp-img{max-height: 560px;}
// 		}
// 	}
// }