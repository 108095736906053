.loader-small_teal {
  display: inline-block; 
  border: 1px solid #26C6AC;
  border-radius: 50%;
  border-top: 1px solid #9FBFCB;
  width: 15px;
  height: 15px;
  animation: spin 0.5s linear infinite;
  border-left: 1px solid #9FBFCB;
  border-right: 1px solid #9FBFCB;
}

.loadel_teal {
  display: inline-block;
  border: 3px solid #26C6AC;
  border-radius: 50%;
  border-top: 3px solid #9FBFCB;
  width: 30px;
  height: 30px;
  animation: spin 0.5s linear infinite;
  border-left: 3px solid #9FBFCB;
  border-right: 3px solid #9FBFCB;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin{
    0%{ transform:rotate(0deg); }
    100%{ transform:rotate(360deg); }
}

@keyframes zoomin{
    0%{ transform:scale(1); }
    100%{ transform:scale(1.03); }
}

@keyframes float {
	0% { transform:translate(0px,0px); }
	50% { transform:translate(0px,-10px); }
	100% { transform:translate(0px,0px); }
}

@keyframes widthshadow1 {
	0% { width:75%; }
	50% { width:100%; }
	100% { width:75%; }
}

@keyframes fadeIn2{
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes arrowIn{
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  74% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes floating {
  0% {
    transform: translate(0,0);  
  }
  25% {
    transform: translate(3px,9px); 
  } 
  50% {
    transform: translate(7px,3px); 
  } 
  75% {
    transform: translate(0,9px); 
  } 
  100% {
    transform: translate(0,0);
  }     
}
@keyframes floating2 {
  0% {
    transform: translate(0,0);  
  }
  25% {
    transform: translate(-3px,-9px); 
  } 
  50% {
    transform: translate(-7px,-3px); 
  } 
  75% {
    transform: translate(0,-9px); 
  } 
  100% {
    transform: translate(0,0);
  }     
}
@keyframes zoomInOut{
  0%{
    transform: scale(1.1);
  }
  50%{
    transform: scale(1.35); 
  }
  100%{
    transform: scale(1.1);
  }
}


@keyframes slideOut{
  0%{
    transform: translateY(0);
    opacity: 0;
  }
  100%{
    transform: translateY(50px);
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-6px);
    -ms-transform: translateY(-6px);
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  60% {
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}