.page--error{
	overflow: hidden;
	.img{
		position: absolute;
		width: 100%;
		height: 100%;
		top:0;
		left:0;
		background-color: #a7967a;
		img{
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;

			opacity: .3;
		}
		&:before,
		&:after{
			content: '';
			height: 100%;
			width: 40%;
			position: absolute;
			top:0;
			z-index: 1;
		}
		&:before{
			left:0;
			background-image: linear-gradient(270deg, rgba(0,0,0,0) 0%, #151515 100%);
		}
		&:after{
			right:0;
			background-image: linear-gradient(270deg, #151515 0%, rgba(0,0,0,0) 100%);
		}
	}
	[class*='container']{
		position: relative;
		z-index: 2;
		color: #c6b9a6;
		text-shadow: 0 2px 6px rgba(0,0,0,.5);
		.content{
			padding-top: 10%;
		}
		h2{
			color: inherit;
		}
		.title{
			font-size: 94px;
			letter-spacing: 6px;
		}
		.subtitle{
			font-size: 42px;
			letter-spacing: 2px;
		}
		.btn--primary2{
			margin-top: 40px;
		}
	}
}


@media only screen and (max-width: 768px){
	.page--error{
		[class*='container']{
			.content{
				text-align: center;
				padding-top: 20%;
			}
			.subtitle{
				font-size: 26px;
				letter-spacing: 1.2px;
			}
			.btn--primary2{
				margin-top: 40px;
			}
		}
	}
}