.ico-ig{
	@include bg-ico(34px, 34px);
	background-image: url(/images/ico/ico-ig.png);
}
.ico-fb{
	@include bg-ico(34px, 34px);
	background-image: url(/images/ico/ico-fb.png);
}
.ico-wa{
	@include bg-ico(34px, 34px);
	background-image: url(/images/ico/ico-wa.png);
}
.ico-yt{
	@include bg-ico(34px, 34px);
	background-image: url(/images/ico/ico-yt.png);
}
.ico-ig-gray{
	@include bg-ico(20px, 20px);
	background-image: url(/images/ico/ico-ig-gray.png);	
	background-size: 20px;
}
.ico-fb-gray{
	@include bg-ico(20px, 20px);
	background-image: url(/images/ico/ico-fb-gray.png);	
	background-size: 20px;
}
.ico-ig-white{
	@include bg-ico(30px, 30px);
	background-image: url(/images/ico/ico-ig-white.svg);	
	background-size: 30px;
}
.ico-wa-white{
	@include bg-ico(30px, 30px);
	background-image: url(/images/ico/ico-wa-white.svg);	
	background-size: 30px;
}
.ico-fb-white{
	@include bg-ico(20px, 20px);
	background-image: url(/images/ico/ico-fb-white.png);	
	background-size: 20px;
}