.page--faq{
	padding-top: 200px;
	min-height: 768px;
	padding-bottom: 20px;
	.title{
		@extend .bodoni;
		margin-bottom: 40px;
		font-size: 52px;
	}
	.collapsible{
		// margin-bottom: 20px;
	}
	.collapsible-button{
		padding: 15px;
		cursor: pointer;
	}
	.collapsible-header{
		border-bottom: 2px solid #c05e62;
		.collapsible-button{
			position: relative;
			h3{
				font-size: 20px;
				color: #c05e62;
			}
			&:after{
				content: '';
				width: 19px;
				height: 16px;
				position: absolute;
				right: 12px;
				top: 17px;
				background-image: url(/images/ico/ico-chevrondown.png);
				background-repeat: no-repeat;
				background-position: center;
				transform: rotate(0deg);
			}
			&.collapse{
				&:after{
					transform: rotate(180deg);
				}
			}
		}
	}
	.collapsible-body{
		font-size: 14px;
		@extend .ptsans;
		padding: 0 15px;
		display: none;
		&.open{
			display: block;
		}
		&:before,
		&:after{
			content: '';
			display: block;
			height: 20px;
		}
		ul{
			margin: 5px 0;
			line-height: 24px;
			list-style-type: disc;
			padding-left: 17px;
			font-family: inherit;
		}
		p{
			margin: 5px 0;
			font-size: inherit;
			font-family: inherit;
		}
		p, b, span,
		i, h1, h2,
		h3, h4, h5,
		h6{
			font-family: inherit !important;
		}
	}
}


@media only screen and (max-width: 768px){
	.page--faq{
		padding-top: 100px;
		min-height: 0;
		.title{
			margin-bottom: 30px;
			font-size: 42px;
		}
		.collapsible-header{
			.collapsible-button{
				padding: 10px;
				padding-right: 30px;
				&:after{
					right: 10px;
					top: 11px;
					background-size: 14px;
				}
				h3{
					font-size: 14px;
					line-height: 18px;
				}
			}
		}
		.collapsible-body{
			&:before,
			&:after{
				content: '';
				display: block;
				height: 10px;
			}
		}
	}	
}