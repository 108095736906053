.page--home{
	.section{
		.section-name{
			top: 35%;
			font-size: 30px;
			left: -50px;
		}
		.bg{
			position: relative;
			width: 100%;
			padding: 0;
			img{
				position: absolute;
				width: 100%;
				height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
			}
		}
		.slick-dots{
			li{
				button{
					&:before{
						font-size: 30px;
						color: #fff;
					}
				}
				.slick-active{
					button{
						&:before{
							color: inherit !important;
						}
					}	
				}
			}
		}
		.home-news-item{
			padding: 10px;
			min-height: 430px;
			background-color: transparent;
			transition: .3s ease;
			.copy{
				padding: 10px 0 15px;
				.title{
					font-size: 16px;
					line-height: 20px;
				}
				.date{
					flex-shrink: 0;
					background-color: #000;
					color: #fff;
					text-align: right;
					padding: 5px;
					min-height: 56px;
					display: block;
				}
			}
			.desc{
				font-family: 'PT Sans', sans-serif;
				font-size: 14px;
				background-color: #fff;
				padding: 0 5px 2px;
				border-top: 4px solid #fff;

				max-height: 0;
				overflow: hidden;
				transition: .4s ease;
			}
			&:hover{
				background-color: #fff;
				transition: .3s ease .1s;
				.desc{
					max-height: 500px;
					transition: 2s ease;
				}
			}
		}
		&.banner{
			.img{
				padding: 0;
				height: 100vh;
				img{
					opacity: .75;
				}
			}
		}
		&.home-about{
			color: #fff;
			height: 100vh;
			.bg{
				height: 100%;
				img{
					opacity: .65;
				}
			}
			.slick-slider,
			.slick-list,
			.slick-track{
				height: 100%;
			}
			.about-content{
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 38%;
				background-color: rgba(54,47,45,.72);
				.content{
					padding: 80px 130px;
					text-align: center;
					.subtitle{
						// font-family: 'Kozuka Gothic Regular';
						font-family: 'Arphic Gyokaisho Heavy JIS';
						margin-bottom: 25px;
					}
					.title{
						font-family: 'Bodoni';
						margin-bottom: 35px;
						font-size: 58px;
						line-height: 58px;
						letter-spacing: 3px;
					}
					.desc{
						text-align: justify
					}
					.btn-link{
						margin-top: 50px;
						letter-spacing: 1.5px;
						font-size: 16px;
						color: #000;
					}
				}
			}
			.slick-dotted{
				.slick-dots{
					text-align: right;
					padding: 0 40px;
					li{
						button{
							&:before{
								color: #fff;
							}
						}
					}
				}
			}
		}
		&.home-res{
			.bg{
				height: 100%;
				position: absolute;
				overflow: hidden;
				img{
					opacity: .53;
					min-height: 1150px;
					top: 0;
					bottom: 0;
					margin: auto;
				}
				&:before{
					content:'';
					width: 100%;
					height: 100%;
					position: absolute;
					top:0;left:0;
					background-color: rgba(0,0,0,.75);
					z-index: 1;
				}
			}
			.home-res-list{
				position: relative;
				padding: 60px 0 10px;
				.section-name{
					color: #fff;
					z-index: 2;
					left: -25px;
				}
				
				.container980{
					z-index: 2;
				}
				.restaurant-list-wrapper{
					min-height: auto;
					padding-top: 50px;
				}
			}
			.home-res-detail{
				color: #fff;
				position: relative;
				z-index: 2;
				transition: .3 ease;
				overflow: hidden;

				height: 0;
				opacity: 0;
				&.show{
					max-height: 720px;
					opacity: 0;
				}
				&:before{
					content:'';
					width: 100%;
					height: 100%;
					position: absolute;
					top:0;left:0;
					background-color: rgba(0,0,0,.3);
				}
				.home-res-detail-header{
					min-height: 100px;
					display: flex;
					align-items: flex-end;
					padding: 10px 0;
					.logo{
						position: relative;
						img{
							margin-right: 10px;
							max-width: 60px;
							max-height: 60px;
						}
						.name{
							letter-spacing: 1.2px;
							font-size: 22px;
						}
					}
					.btn-close{
						font-size: 40px;
						width: 23px;
						height: 23px;
						line-height: 25px;
						position: absolute;
						right: 50px;
						top: 40px;
						&:hover{
							opacity: .8;
						}
					}
				}
				.home-res-copy{
					.link{
						position: absolute;
						bottom: 25px;
						right: 0;
						font-size: 14px;
						letter-spacing: 2px;
						color: #fff;
					}
					[class*="container"]{
						padding: 40px 0 60px;
					}
					.row{
						margin-right: -15px;
						margin-left: -15px;
						.col{
							padding: 0 15px;
						}
					}
					.address{
						p{
							line-height: 18px;
							font-size: 13px;
						}
						.telp,
						.email{
							display: block;
							margin-top: 10px;
						}
					}
					.desc{
						p{
							line-height: 20px;
						}
					}
					.socmed{
						ul{
							margin-left: 16px;
							li{
								display: flex;
								align-items: center;
								margin-right: 25px;
								&:last-child{
									margin-right: 0;
								}
								i{
									flex-shrink: 0;
									margin-right: 8px;
								}
								a{
									opacity: 1;
									transition: .3s ease;
									&:hover{
										opacity: .8;
									}
								}
							}
						}
					}
					.action{
						margin-top: 20px;
						.btn--primary{
							margin-right: 20px;
							font-size: 16px;
							&:last-child{
								margin-right: 0;
							}
						}
					}
				}
				.detail-gallery{
					margin-left: -3px;
					margin-right: -3px;
					display: flex;
					align-items: center;
					width: 100%;
					white-space: nowrap;
					.detail-gallery-item{
						width: susy-span(2 of 12);
						min-width: 100px;
						flex-shrink: 0;
						a{
							display: block;
							padding: 3px;
						}
						.img{
							position: relative;
							padding-bottom: 100%;
							img{
								width: 100%;
								height: 100%;
								position: absolute;
								object-fit: cover;
							}
						}
					}
					.slick-prev,
					.slick-next{
						z-index: 1;
						width: 40px;
						height: 62px;
						&:before{
							content: '';
							width: inherit;
							height: inherit;
							background-image: url(/images/ico-chevronleft.png);
							background-repeat: no-repeat;
							background-size: 40px;
							display: inline-block;
						}
					}
					.slick-prev{
						left: 25px;
						&:before{
							
						}
					}
					.slick-next{
						right: 25px;
						&:before{
							transform: rotate(180deg);
						}
					}
				}
			}
		}
		&.home-news{
			min-height: 700px;
			[class*="container"]{
				padding: 120px 0;
			}
			.section-name{
				top: 90px;
				left: 40px;
			}
			.row{
				margin-left: -10px;
				margin-right: -10px;
				.col{
					padding: 0 10px;
					a{
						display: block;	
					}
					.img{
						position: relative;
						width: 100%;
						/* display: block; */
						padding-bottom: 65%;
						img{
							position: absolute;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}
			.link{
				position: absolute;
				bottom: 25px;
				right: 0;
				font-size: 20px;
				letter-spacing: 2px;
				color: #fff;
			}
			.thumb-video{
				position: relative;
				.img{
					position: relative;
					&:before{
						content: '';
						width: 70px;
						height: 70px;
						background-image: url(/images/ico/ico-play.png);
						background-repeat: no-repeat;
						background-position: center;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						margin: auto;
						opacity: .9;
					}
				}
				&:hover{
					.img{
						&:before{
							opacity: 1;
						}
					}
				}
			}
		}


		&.mobile-home-about{
			display: none;
		}
	}
}

@media only screen and (max-width: 1280px){
	.page--home{
		.section{
			&.home-news{
				[class*="container"]{
					width: 1024px;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px){
	.page--home{
		.section{
			&.home-about,
			&.home-res,
			&.home-news{
				display: none;
			}
			&.mobile-home-about{
				display: block;
				.bg{
					height: 100vh;
				}
				.section-name{
					position: relative;
					top:0;left:0;
					transform: rotate(0deg);
					font-size: 20px;
					margin-bottom: 7px;
				}
				.content{
					width: 60%;
					position: absolute;
					top:0;left:0;
					padding: 30px 0 20px 20px;
					.subtitle{
						font-size: 22px;
						margin-bottom: 7px;
						font-family: 'Arphic Gyokaisho Heavy JIS';
					}
					.title{
						margin-bottom: 20px;
						line-height: 45px;
					}
					.btn-link{
						margin-top: 50px;
					}
					.desc{
						p{
							font-size: 12px;
							line-height: 18px;
						}
					}
				}
			}
			&.mobile-home-res{
				color: #fff;
				padding: 0;
				height: 100vh;
				.section-name{
					left: -70px;
					top: 150px;
				}
				.bg{
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					height: 100%;
					width: 100%;
					&:after{
						content: '';
						width: 100%;
						height: 100%;
						background-color: rgba(0,0,0,.70);
						position: absolute;
						top:0;left:0;
					}
					img{
						position: absolute;
						height: 100%;
						width: 100%;
						object-fit: cover;
						opacity: .53;
					}
				}
				.m-res-list{
					padding: 20px 15px 20px 70px;
					position: relative;
					.accordion{
						.accordion-list{
							.accordion-body{
								ul{
									li{
										&:first-child{
											border-top: 2px solid #fff;
										}
										border-bottom: 2px solid #fff
									}
								}
							}
						}
					}
				}
			}
			&.mobile-home-news{
				.section-name{
					top: 20%;
					left: -10px;
				}
				.m-home-news-list{
					background-color: #fff;
				}
				.home-news-item{
					min-height: unset;
					background-color: #fff !important;
					margin-bottom: 20px;
					.desc{
						max-height: unset;
						overflow: visible;
						transition: .4s ease;
					}
				}
			}
		}
	}
}