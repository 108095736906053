// Reset
@import "reset/normalize";

// Css Framework
@import "susy/sass/susy";

/* Modules */
@import "modules/animate";
@import "modules/breakpoint";
@import "modules/fonts";
@import "modules/configuration";
@import "modules/mixin";

/* Partials */
@import "partials/base";
@import "partials/typography";
@import "partials/grid";
@import "partials/form";
@import "partials/button";
@import "partials/icon";
@import "partials/helper";
@import "partials/other";
@import "partials/header";
@import "partials/footer";

/* Pages */
@import "pages/home";
@import "pages/contact";
@import "pages/news";
@import "pages/about";
@import "pages/restaurant";
@import "pages/faq";
@import "pages/error";
@import "pages/promo";
@import "pages/download";

/* Vendor */
@import "vendor/datepicker";
@import "vendor/slick";
@import "vendor/magnificpopup";

